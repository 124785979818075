var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a;
import { createSlice, current } from '@reduxjs/toolkit';
import { IStatus } from '../../../@types/status';
import { addObjectToSubFolder, createNewFolder, findFolderById, removeFileFromFolderDocs, removeFolderById, } from '../../../utils/filterFolderData';
import { createFolder, deleteFolder, getAllFolder, getOneFolder, removeFileFromFolder, updateFolder, uploadFile, } from './actions';
var initialState = {
    folders: {
        docs: [],
        meta: {},
    },
    folder: {},
    status: IStatus.IDLE,
    selectedFolder: null,
};
var slice = createSlice({
    name: 'folders',
    initialState: initialState,
    reducers: {
        setSelectedFolder: function (state, _a) {
            var payload = _a.payload;
            state.selectedFolder = payload;
        },
        clearFolder: function (state) {
            state.selectedFolder = null;
        },
        getCurrentFolderState: function (state, _a) {
            var payload = _a.payload;
            state.selectedFolder = findFolderById(current(state.folders.docs), payload.folderId);
        },
    },
    extraReducers: function (builder) {
        builder
            .addCase(getAllFolder.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getAllFolder.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.folders = payload;
        })
            .addCase(getAllFolder.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(getOneFolder.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(getOneFolder.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.folder = payload;
        })
            .addCase(getOneFolder.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(createFolder.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(createFolder.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.folder = payload.data;
            state.folders = __assign(__assign({}, state.folders), { docs: createNewFolder(payload.data.parentFolder, payload.data, current(state.folders.docs)) });
        })
            .addCase(createFolder.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(deleteFolder.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(deleteFolder.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.folders.docs = removeFolderById(current(state.folders.docs), payload.deleteFolderId);
            state.folder = __assign({}, payload.data);
        })
            .addCase(deleteFolder.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(removeFileFromFolder.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(removeFileFromFolder.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.folders.docs = removeFileFromFolderDocs(current(state.folders.docs), payload.fileId);
            state.folder = __assign({}, payload.data);
        })
            .addCase(removeFileFromFolder.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(uploadFile.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(uploadFile.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.folder = payload.data;
            state.folders = __assign(__assign({}, state.folders), { docs: addObjectToSubFolder(payload.data.parentFolder, payload.data, current(state.folders.docs)) });
        })
            .addCase(uploadFile.rejected, function (state) {
            state.status = IStatus.FAILED;
        })
            .addCase(updateFolder.pending, function (state) {
            state.status = IStatus.LOADING;
        })
            .addCase(updateFolder.fulfilled, function (state, _a) {
            var payload = _a.payload;
            state.status = IStatus.SUCCEEDED;
            state.folder = payload.data;
            state.folders = __assign(__assign({}, state.folders), { docs: addObjectToSubFolder(payload.data.parentFolder, payload.data, current(state.folders.docs)) });
        })
            .addCase(updateFolder.rejected, function (state) {
            state.status = IStatus.FAILED;
        });
    },
});
export var setSelectedFolder = (_a = slice.actions, _a.setSelectedFolder), clearFolder = _a.clearFolder, getCurrentFolderState = _a.getCurrentFolderState;
export default slice.reducer;
